.container-slider {
    margin-top: 10px;
}



.slider-wrapper {
    position: relative;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
}

.slider {
    display: flex;
    width: 95%;
    justify-content: center;
    aspect-ratio: 16/9;
    box-shadow: 0 24 48 -12 hsla(0, 0%, 0%, 0.25);
}

.slide {
    border-radius: 10px;
    width: inherit;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
}

.active-anim {
    opacity: 1;
}

.slider-nav {
    display: none;
    column-gap: 16px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.container-slider:hover .slider-nav {
    display: flex;
    cursor: pointer;
}

.dot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.75;
    transition: opacity ease 250ms;
}

.dot:hover {
    opacity: 1;
    background-color: #0E9444;
}

.active {
    background: #0E9444;
}


.slider-nav-arrow {
    display: none;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 40%;
    z-index: 990;
}

.divArrowLeft,
.divArrowRight {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    width: 60px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
}

.divArrowLeft {
    left: 5%;
}

.divArrowRight {
    right: 5%;
}

.arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
}

.right {
    margin-right: 5px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    margin-left: 5px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.divArrowRight:hover,
.divArrowLeft:hover {
    background-color: #0E9444;
}

.container-slider:hover .slider-nav-arrow {
    display: flex;
}