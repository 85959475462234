.card {
  width: 345px;
  height: 543px;
  display: flex;
  flex-direction: column;
  margin: 7px;
  transition: 0.3s;
  background-color: white;
  border-radius: 5px;
}

.card:hover {
  transform: scale(1.01);
}

.card-img img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.card-desc {
  padding: 15px 15px;
}

.card-desc p {
  margin: 15px 0px;
}

.card-title h3 {
  font-size: 20px;
}

.card-text p {
  text-align: justify;
  font-size: 16px;
}

@media screen and (max-width: 804px) {
  .card {
    width: auto;
    height: fit-content;
  }
}
