.container-institutional {
    width: 100%;
    margin-top: 20px;
    padding-top: 30px;
    background-image: linear-gradient(#ffffff4d, #b3b7b14d);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-institutional h2 {
    font-size: 40px;
}

.institutional-details {
    max-width: 1200px;
    display: flex;
    margin-top: 20px;
    padding: 0px 10px
}

.institutional-img,
.institutional-text {
    display: flex;
    flex-direction: column;
}

.institutional-text {
    width: 60%;
}

.institutional-img {
    width: 40%;
}

.institutional-text {
    padding: 0px 30px;
}

.institutional-img {
    justify-content: flex-start;
    align-items: center;

}

.institutional-img img {
    margin-top: 10px;
    max-width: 98%;
    border-radius: 7px;
}

.institutional-text h2 {
    font-size: 30px;
}

.institutional-text p,
.institutional-text h2,
.institutional-text h3 {
    margin: 10px 0px;
}


.divMain-mission-purpose-values {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
}

.div-mission,
.div-purpose,
.div-values {
    padding: 0px 30px;
    width: 250px;
    text-align: center;
    margin: 35px 0px;
}

.div-values {
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
}

.div-mission img,
.div-purpose img,
.div-values img {
    height: 100px;
}

.div-mission p,
.div-purpose p,
.div-values p {
    margin: 20px 0px;
}

.div-mission h3,
.div-purpose h3,
.div-values h3 {
    font-size: 30px;
    margin: 10px 0px;
}

@media screen and (max-width: 768px) {

    .institutional-details {
        flex-direction: column;
        align-items: center;
    }

    .institutional-img,
    .institutional-text {
        width: 95%;
    }

    /* .institutional-mission {
        padding: 20px;
    } */

}

@media screen and (max-width: 720px) {
    /* .institutional-mission {
        flex-direction: column;
    }

    .mission,
    .purpose {
        width: auto;
    } */

}