.contact-container {
    width: 100%;
    /* background-color: #407153; */
    background-color: #282c28;
}

.details {
    max-width: 1050px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 100px;
    color: #f2f5f3;
    margin: 0 auto;
}

.details  p {
    margin: 2px 0px;
    text-align: center;
}

.contact-container h2 {
    margin: 20px 0px;
    font-size: 30px;
}

.divider {
    border-top: 1px solid rgba(196, 196, 196, 0.4);
    margin: 20px;
    width: 80%;
}

.rights {
    color: #acafad;
    font-size: 14px;
}