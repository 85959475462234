.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
}

.item {
  display: flex;
  align-items: center;
  padding: 0 2px;
  gap: 5px;
  margin-top: 5px;
  color: grey;
  font-size: 18px;
  width: fit-content;
}

.titleMtr {
  color: #99bd11;
}
