.filterDiv {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 5px;
  align-items: flex-end;
}

.inputSearch {
  width: 150px;
}

.selectSearch {
  width: 200px;
}

.inputWithLabel {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.labelSearch {
  font-size: smaller;
}
