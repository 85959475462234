.navbar,
.nav-menu {
    display: flex;
    align-items: center;
}

.navbar {
    justify-content: center;
    width: 100%;
    height: 90px;
    z-index: 999;
    background-color: #fff;
    position: relative;
    transition: all ease-in-out 0.2s;
}

.navbar.sticky {
    top: 0;
    left: 0;
    right: 0;
    position: sticky;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    transition: all ease-in-out 0.2s;
}

.container-navbar {
    width: 1150px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-logo {
    width: 20%;
    width: 150px;
    padding-left: 30px;
}

.navbar-logo>img {
    width: 100%;
}

.nav-menu {
    width: 80%;
    justify-content: flex-end;
    margin-right: 30px;
}

.nav-menu>a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    margin: 0px 20px
}

.nav-menu>a:visited {
    color: #000
}

.nav-menu>a:hover {
    color: #0E9444;
}

.nav-menu-mobile {
    display: none;
}

/* Icon Menu Mobile (bars and times) */
.menu-icons-nav {
    cursor: pointer;
    display: none;
    margin-right: 30px;
}

.bar1,
.bar2,
.bar3 {
    width: 31px;
    height: 3.5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

.change.bar1 {
    transform: translate(0, 9px) rotate(-45deg);
}

.change.bar2 {
    opacity: 0;
}

.change.bar3 {
    transform: translate(0, -10px) rotate(45deg);
}


@media screen and (max-width: 960px) {
    .container-navbar {
        justify-content: space-between;
    }

    .menu-icons-nav {
        display: block;
    }

    .nav-menu-mobile {
        display: block;
    }

    .nav-menu {
        background-color: #fff;
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 90vh;
        top: -100px;
        left: 0%;
        position: absolute;
        opacity: 1;
        --webkit-transition: top 0.9s ease-in;
        -moz-transition: top 0.9s ease-in;
        -o-transition: top 0.9s ease-in;
        transition: top 0.9s ease-in;

    }

    .nav-menu.active {
        display: flex;
        background-color: #fff;
        left: 0%;
        top: 70px;
        opacity: 1;
        z-index: 1;
        --webkit-transition: top 0.9s ease-in;
        -moz-transition: top 0.9s ease-in;
        -o-transition: top 0.9s ease-in;
        transition: top 0.9s ease-in;
    }

    .nav-menu>a {
        font-size: 22px;
        font-weight: 600;
        padding: 20px 0px;
    }

    .nav-menu>a:hover {
        background-color: #0E9444;
        color: #fff;
        padding: 20px 100%;
    }




}