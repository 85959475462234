.backToTop {
    display: none;

}

.backToTop i {
    color: #FFF;
}

.backToTop.isActive {
    display: block;
    position: fixed;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0E9444;
    border: 1px solid #0E9444;
    border-radius: 5px;
    z-index: 1;
    right: 30px;
    bottom: 30px;
    cursor: pointer;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 4px;
}
