.titleMonth {
  margin: 0px 5px;
  padding: 0;
}

.collapse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.divTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #808080;
}

.divTitle:hover {
  color: #99bd11;
}

.divTitle:hover .seta {
  box-shadow: 2px 2px 0 0px #99bd11 inset;
}

.divTitle:hover .anticon.anticon-folder {
  color: #99bd11;
}

.divSeta {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.seta {
  position: absolute;
  padding: 4px;
  box-shadow: 2px 2px 0 0px #808080 inset;
  border: solid transparent;
  border-width: 0 0 2px 2px;
  transform: rotate(135deg);
  transition: transform ease-in-out 0.2s;
}

.seta.down {
  transform: rotate(225deg);
  transition: transform ease-in-out 0.2s;
}

.listFiles-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.listFiles {
  margin: 5px;
}

.listFiles p {
  margin: 0;
  padding: 3px;
}

.listFiles a {
  color: #808080;
}

.listFiles a:hover {
  color: #99bd11;
}

.listFiles a:visited {
  color: #808080;
}

.file {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
}

.file img {
  width: 20px;
  height: 20px;
}

.msgNoFiles {
  display: none;
}

.msgNoFiles.true {
  display: block;
}
