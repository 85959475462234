.form {
  width: 50%;
  background-color: rgba(255, 255, 255, 0.33);
  box-shadow: 0px 8px 32px 0 rgba(0, 0, 255, 0.33);
  backdrop-filter: blur(3px);
  padding: 0px 16px 16px 16px;
  border-radius: 16px;
}

.containerForm {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.title {
  color: #99bd11;
}

.submitButton {
  background-color: #99bd11;
}
