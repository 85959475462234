.userCardsContainer {
  width: fit-content;
  flex-wrap: wrap;
  display: flex;
  padding: 10px;
  gap: 10px;
}

.ant-card-body {
  display: flex;
  justify-content: center;
}
