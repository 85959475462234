.cardComponentContainer {
    width: 300px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid rgb(234, 234, 234);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    height: 270px;
}

.cardComponentHeader,
.cardComponentBody {
    background-color: rgb(250, 255, 248);
}

.cardComponentHeader,
.cardComponentBody,
.cardComponentCTA {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardComponentHeader {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 0px 24px;
    min-height: 56px;
    border-bottom: 1px solid rgb(234, 234, 234);
    font-weight: bold;
    color: #00000E
}

.cardComponentBody {
    padding: 24px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom: 1px solid rgb(234, 234, 234);
    z-index: 999;
    position: block;

}

.cardComponentCTA {
    padding: 12px 24px;
    padding-top: 30px;
    font-size: 20px;
    font-weight: bold;
    height: 45px;
    position: absolute;
    top: 100;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(208, 216, 178, 0.5);
    z-index: -1;
    color: #c1c1c1;
    transition: box-shadow 0.2s ease-in;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.cardComponentContainer:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 3px 9px 0px;
    transition: box-shadow 0.2s ease-in;
    cursor: pointer;
}

.cardComponentContainer:hover .cardComponentCTA {
    background-color: rgb(153, 189, 18);
    color: #00000E;
    transition: all 0.2s ease-in;
}