.container-solutions {
    background-color: #8fab9a4d;
    padding: 40px 0px;
}

.container-solutions,
.solutions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.solutions h2 {
    font-size: 30px;
}