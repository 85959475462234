.container-cards{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

@media screen and (max-width: 780px) {
    .container-cards {
        flex-direction: column;
    }
}