header {
  background-color: #99bd11;
  height: fit-content;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.companyAndIcon {
  display: flex;
  align-items: center;
  gap: 20px;
}

.iconHome {
  color: aliceblue;
  font-size: 25px;
}

.iconHome :hover {
  color: #047d2c;
  cursor: pointer;
}

.companyName {
  color: aliceblue;
}

header img {
  width: 80px;
}
