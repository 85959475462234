.button-ClienteArea {
    /* padding: 10px 20px; */
    width: 135px;
    height: 25px;
    padding: 7px;
    margin: 0px 13px;
    color: #0E9444;
    border-radius: 5px;
    border: 1px solid #0E9444;
    font-size: 18px;
}

.button-ClienteArea,
.button-ClienteArea-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button-ClienteArea:hover {
    color: #fff;
    background-color: #0E9444;
}

.button-ClienteArea-mobile {
    font-size: 22px;
    font-weight: 600;
    width: 170px;
    padding: 15px 20px;
    border: 1px solid #0E9444;
    color:#0E9444;
    border-radius: 5px;
    margin: 20px 0px;
}

.button-ClienteArea-mobile:hover {
    background-color: #0E9444;
    color: #fff;
}

