.ant-card-body {
  display: flex;
  justify-content: center;
}

.cardsContainer {
  display: flex;
  margin: 10px;
  gap: 10px;
}
