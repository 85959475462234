.appBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url('../images/gestaoAmbiental.jpg');
  background-size: cover;
}

.loginForm {
  background-color: rgba(255, 255, 255, 0.33);
  box-shadow: 0px 8px 32px 0 rgba(0, 0, 255, 0.33);
  backdrop-filter: blur(3px);
  padding: 0px 16px 16px 16px;
  border-radius: 16px;
}
